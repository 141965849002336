import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment-mini';

@Injectable()
export class StatisticsService {
	constructor(private httpClient: HttpClient) {}

	public getStatistics(fromDate: Moment, jobsiteIds: string[], toPeriod: Moment) {
		return this.httpClient.post<StatisticsResponseDto>(
			`/webapi/auth/statistics`,
			new StatisticsRequestDto(fromDate, toPeriod, jobsiteIds),
		);
	}
}

export class StatisticsRequestDto {
	constructor(fromPeriod: Moment, toPeriod: Moment, jobsiteIds: string[]) {
		this.FromPeriod = fromPeriod.toISOString();
		this.ToPeriod = toPeriod.toISOString();
		this.JobsiteIds = jobsiteIds;
	}

	public FromPeriod: string;
	public JobsiteIds: string[];
	public ToPeriod: string;
}

export interface StatisticsResponseDto {
	Currency: string;
	TotalAmount: number;
	TotalRentedAmount: number;
	TotalSalesAmount: number;
	Categories: StatisticsCategory[];
}

export interface StatisticsCategory {
	Currency: string;
	Id: number;
	Name: string;
	TotalRentedAmount: number;
	TotalSalesAmount: number;
	SubCategories: StatisticsSubCategory[];
}

export interface StatisticsSubCategory {
	Currency: string;
	Id: string;
	Name: string;
	TotalRentedAmount: number;
	TotalSalesAmount: number;
}
